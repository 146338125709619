import { PropsWithChildren, useCallback, useState } from "react";
import { useOnboarding } from "./useOnboarding";
import Joyride, { ACTIONS, EVENTS, STATUS, CallBackProps } from "react-joyride";
import "./Onboarding.css";
import { clsx } from "clsx";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button.tsx";

export const OnboardingWrapper = ({ children }: PropsWithChildren) => {
  const {
    steps,
    isReady,
    setIsReady,
    nextOnboardingStep,
    onboardingCanShow,
    stopOnboarding,
    prevOnboardingStep,
    setCurrentOnboardingStep,
    currentOnboardingStep,
    setOnboardingCanShow,
  } = useOnboarding();
  const handleJoyrideCallback = useCallback((data: CallBackProps) => {
    const { action, index, origin, status, type, step, lifecycle } = data;
    if (action === ACTIONS.PREV && lifecycle === "complete") {
      prevOnboardingStep();
      return;
    }
    if (action === ACTIONS.NEXT && lifecycle === "complete") {
      nextOnboardingStep();
      return;
    }
    if (
      action === ACTIONS.CLOSE
      // && origin === ORIGIN.KEYBOARD
    ) {
      stopOnboarding();
      setCurrentDialogStep(index);

      return;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      console.log("TARGET_NOT_FOUND");
      setIsReady(false);
      // Update state to advance the tour
      // setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setIsReady(false);
    }
  }, []);

  const currentStepClickable = steps[currentOnboardingStep]?.clickable ?? true;
  const [currentDialogStep, setCurrentDialogStep] = useState(null);

  return (
    <div className={clsx({ "overflow-hidden": !onboardingCanShow })}>
      <Dialog open={currentDialogStep !== null}>
        <DialogContent className="sm:max-w-[425px] [&>button]:hidden ">
          <DialogHeader>
            <DialogTitle>
              <span className={"text-base"}>
                {" "}
                Are you sure you want to finish onboarding?
              </span>
            </DialogTitle>
          </DialogHeader>

          <DialogFooter className={"gap-4"}>
            <Button
              variant="ghost"
              onClick={() => {
                setCurrentDialogStep(null);
                setCurrentOnboardingStep(0);

                setIsReady(false);
                setOnboardingCanShow(false);
              }}
            >
              Yes, finish
            </Button>
            <Button
              variant={"accent"}
              onClick={() => {
                setCurrentOnboardingStep(currentDialogStep);
                setCurrentDialogStep(null);
                setIsReady(false);
                setOnboardingCanShow(true);
              }}
            >
              No, take me back
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      {onboardingCanShow && (
        <Joyride
          disableCloseOnEsc
          showSkipButton={false}
          // hideCloseButton={true}
          disableScrolling
          disableOverlayClose
          continuous
          steps={steps}
          run={isReady && onboardingCanShow}
          spotlightClicks={currentStepClickable}
          stepIndex={currentOnboardingStep}
          callback={handleJoyrideCallback}
          styles={{
            options: {
              backgroundColor: "#f9f9f9",
              // overlayColor: "#5882bd",
              // textColor: "#1e1e1e",
              primaryColor: "#FF8000",
              width: 300,
              zIndex: 1000,
            },
          }}
        />
      )}

      {children}
    </div>
  );
};
