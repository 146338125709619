import * as React from "react";

import { cn } from "@/lib/utils";
import { Eye, EyeOff } from "lucide-react";
import { Button } from "@/components/ui/button.tsx";
import { useState } from "react";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  accentBorder?: boolean;
  password?: boolean;
  onlyInteger?: boolean;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ accentBorder, className, password, onlyInteger, type, ...props }, ref) => {
    const [showPassword, setShowPassword] = useState(false);

    return (
      <div className="flex flex-row relative">
        {onlyInteger ? (
          <input
            type={password ? (showPassword ? "text" : "password") : type}
            className={cn(
              `${accentBorder ? "focus:border-b-accent-1" : "focus:border-accent-1"} flex h-9 w-full rounded-md-native border border-input bg-transparent px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-gray-400 placeholder:font-light focus-visible:outline-none  focus-visible:ring-transparent disabled:cursor-not-allowed disabled:opacity-50`,
              className,
            )}
            ref={ref}
            {...props}
          />
        ) : (
          <input
            type={password ? (showPassword ? "text" : "password") : type}
            className={cn(
              `${accentBorder ? "focus:border-b-accent-1" : "focus:border-accent-1"} flex h-9 w-full rounded-md-native border border-input bg-transparent px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-gray-400 placeholder:font-light focus-visible:outline-none  focus-visible:ring-transparent disabled:cursor-not-allowed disabled:opacity-50`,
              className,
            )}
            ref={ref}
            {...props}
          />
        )}
        {password && (
          <Button
            type="button"
            variant="ghost"
            size="sm"
            className="absolute right-0 top-0 h-full px-3 py-2 hover:bg-transparent"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? (
              <Eye className="h-4 w-4 text-gray-500" />
            ) : (
              <EyeOff className="h-4 w-4 text-gray-500" />
            )}
          </Button>
        )}
      </div>
    );
  },
);
Input.displayName = "Input";

export { Input };
