import { Outlet } from "react-router-dom";
import { IS_PROD } from "@/consts/consts.ts";
import Intercom from "@intercom/messenger-js-sdk";

export const TestLayout = () => {
  if (IS_PROD) {
    Intercom({
      app_id: import.meta.env.VITE_INTERCOM_ID,
    });
  }
  return <Outlet />;
};
