import { Label } from "@/components/ui/label.tsx";
import { FormInput } from "@/components/form/FormInput.tsx";
import { PASSWORD_PATTERNS } from "@/consts/consts.ts";
import { Button } from "@/components/ui/button.tsx";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
} from "@/components/ui/card";
import { useLoginForm } from "@/pages/LoginPage/useLoginForm.tsx";
import useResetPasswordForm from "@/pages/LoginPage/LoginForm/useResetPasswordForm.tsx";
import { useState } from "react";
import { ChevronLeft } from "lucide-react";
import { IS_STAGING } from "@/consts/consts.ts";

export const LoginForm = () => {
  const { form, onSubmit } = useLoginForm();
  const { resetForm, onSubmit: onResetSubmit } = useResetPasswordForm();
  const [showResetPassword, setShowResetPassword] = useState(false);

  const site = IS_STAGING
    ? "https://staging-site.nextbigniche.ai/#plans-and-pricing"
    : "https://nextbigniche.ai/#plans-and-pricing";
  return (
    <div className="flex relative w-full items-center justify-center">
      <div className="flex relative w-96 items-center overflow-hidden">
        <div
          className={`flex items-center transition ${showResetPassword ? "-translate-x-1/2" : ""}`}
        >
          <Card className="!border-none !shadow-none w-full min-w-[375px] flex flex-col gap-4">
            <CardHeader>
              <div
                className={"flex flex-col items-center justify-center gap-4"}
              >
                <p className="text-text-2 font-light text-2xl">Log In</p>
                <p className={"font-semibold text-text-1 text-3xl"}>
                  Next Big Niche
                </p>
              </div>
            </CardHeader>
            <CardContent className="w-full">
              <form>
                <div className="grid w-full items-center gap-4">
                  <div className="flex flex-col space-y-1.5">
                    <Label className={"text-muted-foreground"}>Email</Label>
                    <FormInput
                      form={form}
                      fieldName={"email"}
                      validation={{
                        required: "Email is required",
                      }}
                    />
                  </div>
                  <div className="flex flex-col space-y-1.5">
                    <Label className={"text-muted-foreground"}>Password</Label>
                    <FormInput
                      password
                      form={form}
                      validation={{
                        value: PASSWORD_PATTERNS,
                        required: "Password is required",
                      }}
                      fieldName="password"
                      type={"password"}
                    />
                    <p
                      className="text-link text-sm cursor-pointer hover:opacity-70 transition"
                      onClick={setShowResetPassword}
                    >
                      Forgot password?
                    </p>
                  </div>
                </div>
              </form>
            </CardContent>

            <CardFooter className="flex justify-between">
              <div className="flex flex-col w-full items-center gap-[14px]">
                <Button onClick={onSubmit} className={" w-full"}>
                  Login
                </Button>

                <div>
                  <span className={"text-muted-foreground"}>
                    New to <span>Next Big Niche</span>?
                  </span>

                  <a
                    target="_blank"
                    href={site}
                    className={
                      "text-link cursor-pointer hover:opacity-60 transition ml-[4px]"
                    }
                  >
                    {" "}
                    Sign Up Now
                  </a>
                </div>
              </div>
            </CardFooter>
          </Card>

          {/* Reset Password Card */}
          <Card className="!border-none !shadow-none w-full min-w-[375px] flex flex-col gap-4">
            <ChevronLeft
              width={28}
              height={28}
              className="ml-4 text-text-2 transition hover:text-gray-300 cursor-pointer"
              onClick={() => setShowResetPassword(false)}
            />
            <CardHeader>
              <div className="flex flex-col items-center">
                <p className="text-text-2 font-light text-2xl">
                  Reset Password
                </p>
              </div>
            </CardHeader>

            <CardContent className="w-full">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  onResetSubmit();
                }}
              >
                <div className="grid w-full items-center gap-4">
                  <div className="flex flex-col space-y-4">
                    <FormInput
                      form={resetForm}
                      fieldName="emailForRecovery"
                      validation={{
                        required: "Email is required",
                      }}
                    />
                    <p className="text-gray-400 text-sm">
                      Enter the email address by which you registered and we
                      will send you a link to reset your password.
                    </p>
                  </div>
                </div>
              </form>
            </CardContent>

            <CardFooter>
              <Button type="submit" className="w-full" onClick={onResetSubmit}>
                Send Link
              </Button>
            </CardFooter>
          </Card>
        </div>
      </div>
    </div>
  );
};
