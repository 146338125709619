import { useOnboardingStore } from "./useOboardingStore";
import { useEffect } from "react";
import { Step } from "react-joyride";

export const ONBOARDING_CLASSES = {
  welcome: "welcome-message",
  wholeTrendStep: "onboarding-trend-step-1",
  tableHeaderStep: "table-header",
  searchTermStep: "onboarding-search-term-step-2",
  topProducts: "onboarding-top-products-step-3",
  searchHistoryStep: "onboarding-search-history-step-4",
  searchHistoryStepClick: "onboarding-search-history-step-5-click",
  searchHistorySeasons: "onboarding-search-history-step-6-seasons",
  searchVolumeColumns: "onboarding-step-7-search-volume-columns",
  searchVolumeSortsHighlight: "onboarding-step-8-search-volume-columns-sort",
  watchListTrendCheckboxClick: "watch-list-trend-checkbox-click",
  watchListAdddButton: "onboarding-step-9-highlight-add-to-watch-list-button",
  blackListAddButton: "add-to-black-list-button",
  blackListMenuHighlight: "black-list-menu-highlight",
  step11: "onboarding-step-9-highlight-filters",
  wholeFilterModalHighlight: "whole-filter-modal-highlight",
  saveSearchHighlight: "save-search-highlight",
  showResultsHighlight: "show-results-highlight",
  savedSearchesHighlight: "saved-searches-highlight",
  lastMessage: "last-message",
};

export const checkStepPositionByName = (stepName: string) => {
  const position = Object.values(ONBOARDING_CLASSES).findIndex(
    (n) => n === stepName,
  );

  return position;
};

type ClickableStep = Step & { clickable?: boolean; hideNextButton?: boolean };

export const useOnboarding = () => {
  const {
    currentOnboardingStep,
    isReady,
    nextStep,
    setIsReady,
    setStop,
    stop,
    onboardingCanShow,
    setOnboardingCanShow,
    startOnboarding,
    prevStep,
    setCurrentOnboardingStep,
  } = useOnboardingStore();

  const steps: ClickableStep[] = [
    {
      target: `.${ONBOARDING_CLASSES.welcome}`,
      content: "Welcome aboard! Let’s learn how to find your Next Big Niche.",
      disableBeacon: true,
      clickable: false,
      placement: "center",
    },
    {
      target: `.${ONBOARDING_CLASSES.wholeTrendStep}`,
      content:
        'This is an Amazon trend. A "trend" represents search terms that show any positive growth in search volume over the previous period.',
      disableBeacon: true,
      clickable: false,
    },
    {
      target: `.${ONBOARDING_CLASSES.tableHeaderStep}`,
      content:
        "Here is the data used to analyze each search term. Hover over each item to expand its explanation.",
      disableBeacon: true,
    },
    {
      target: `.${ONBOARDING_CLASSES.searchTermStep}`,
      content:
        "This is a search term. Click on it to open the Amazon marketplace in the new tab.",
      disableBeacon: true,
      placement: "bottom-start",
    },
    {
      target: `.${ONBOARDING_CLASSES.topProducts}`,
      content:
        "These are the top 3 most clicked products for this search term. Hover over each product for more details or click to view on Amazon in the new tab.",
      disableBeacon: true,
    },
    {
      target: `.${ONBOARDING_CLASSES.searchHistoryStep}`,
      content:
        "This is the search volume history. Click on it to expand and see detailed trend changes over time.",
      disableBeacon: true,
      // hideFooter: true,
      hideFooter: false,
      hideNextButton: true,
    },

    {
      target: `.${ONBOARDING_CLASSES.searchHistoryStepClick}`,
      content:
        "Here is the search volume chart, segmented by seasons to highlight seasonal trends.",
      disableBeacon: true,
    },

    {
      target: `.${ONBOARDING_CLASSES.searchHistorySeasons}`,
      content:
        "The color coding represents different seasons. Check the legend here to understand the seasonality of trends.",
      disableBeacon: true,
    },
    {
      target: `.${ONBOARDING_CLASSES.searchVolumeColumns}`,
      content:
        "The search volume change column displays both percentage and numerical changes. You can sort the trends by either value.",
      disableBeacon: true,
    },
    {
      target: `.${ONBOARDING_CLASSES.searchVolumeSortsHighlight}`,
      content:
        "You can sort the trends over 12, 6, or 3 months, using either percentage or numerical values. Click to choose your sorting preference.",
      disableBeacon: true,
      clickable: false,
    },
    {
      target: `.${ONBOARDING_CLASSES.watchListTrendCheckboxClick}`,
      content:
        "You can mark a trend as a favorite to add it to your watch list to track its dynamics. Let's try adding one now.",
      disableBeacon: true,
      clickable: true,
    },
    {
      target: `.${ONBOARDING_CLASSES.watchListAdddButton}`,
      content: "And add it to watch list",
      disableBeacon: true,
      clickable: true,
    },
    {
      target: `.${ONBOARDING_CLASSES.blackListAddButton}`,
      content:
        "Alternatively, add a trend to the blacklist if you no longer wish to see it.",
      disableBeacon: true,
      clickable: false,
    },

    {
      target: `.${ONBOARDING_CLASSES.blackListMenuHighlight}`,
      content: "You can find and edit your watch list and blacklist here. ",
      disableBeacon: true,
      clickable: false,
    },
    {
      target: `.${ONBOARDING_CLASSES.step11}`,
      content:
        "Filter trends using various parameters. Click the filters button to adjust your search.",
      disableBeacon: true,
      clickable: true,
      hideFooter: false,
    },
    {
      target: `.${ONBOARDING_CLASSES.wholeFilterModalHighlight}`,
      content:
        "Customize your search criteria to filter the search terms accordingly.",
      disableBeacon: true,
      placement: "auto",
      clickable: false,
    },
    {
      target: `.${ONBOARDING_CLASSES.saveSearchHighlight}`,
      content: "Save your search settings to easily access them later. ",
      disableBeacon: true,
      clickable: false,
    },
    {
      target: `.${ONBOARDING_CLASSES.showResultsHighlight}`,
      content: 'Сlick "Show Results" to filter the search terms.',
      disableBeacon: true,
      hideFooter: false,
      clickable: true,
    },
    {
      target: `.${ONBOARDING_CLASSES.savedSearchesHighlight}`,
      content: 'Click "Saved Searches" to revisit your saved search settings.',
      disableBeacon: true,
      hideFooter: false,
      clickable: false,
    },
    {
      target: `.${ONBOARDING_CLASSES.welcome}`,
      content:
        "If you have more questions, please contact us for assistance. Good luck with finding your Next Big Niche!",
      disableBeacon: true,
      placement: "center",
    },
  ];

  useEffect(() => {
    if (stop) {
      setIsReady(false);
      return;
    }
    const t = setTimeout(() => {
      setIsReady(true);
      clearTimeout(t);
    }, 100);
    return () => clearTimeout(t);
  }, [isReady, setIsReady, stop]);

  return {
    currentOnboardingStep,
    isReady,
    steps,
    setIsReady,
    nextOnboardingStep: nextStep,
    prevOnboardingStep: prevStep,
    onboardingCanShow,
    stopOnboarding: setStop,
    startOnboarding,
    isOnboarding: true,
    setCurrentOnboardingStep,
    setOnboardingCanShow,
  };
};
