import { Label } from "@/components/ui/label.tsx";
import { FormInput } from "@/components/form/FormInput.tsx";
import { EMAIL_PATTERN, PASSWORD_PATTERNS } from "@/consts/consts.ts";
import { useChangePassword } from "@/pages/SettingsPage/hooks/useChangePassword.tsx";
import { Button } from "@/components/ui/button.tsx";
import { useChangeEmail } from "@/pages/SettingsPage/hooks/useChangeEmail.tsx";
import {
  Dialog,
  DialogFooter,
  DialogHeader,
  DialogTrigger,
  DialogContent,
  DialogTitle,
  DialogClose,
} from "@/components/ui/dialog.tsx";
import { useNavigate } from "react-router-dom";
import { DialogDescription } from "@radix-ui/react-dialog";
import useMenuStore from "@/store/useMenuStore.tsx";
import { useEffect } from "react";

export const Settings = () => {
  const { form, onSubmit } = useChangePassword();
  const { emailForm, onSubmitEmail } = useChangeEmail();

  const navigation = useNavigate();

  const handleUnsubscribe = () => {
    navigation("/cancel-newsletter");
  };

  const { setMenuShown } = useMenuStore();

  useEffect(() => {
    setMenuShown(true);
  }, []);

  return (
    <div className="p-4 w-full">
      <div className="text-xl font-bold">Settings</div>
      <div className="flex flex-col items-start gap-4 translate h-2/3 w-full mt-8">
        <Dialog>
          <DialogTrigger asChild>
            <Button size={"sm"} className="w-52" variant={"outline"}>
              Change password
            </Button>
          </DialogTrigger>
          <DialogContent className="sm:max-w-[425px] rounded-lg-native">
            <DialogHeader className="font-medium text-xl mb-4">
              Change password
            </DialogHeader>
            <DialogTitle hidden />
            <DialogDescription>
              <form className="flex flex-col gap-4">
                <div className="flex flex-col space-y-1.5">
                  <Label className="text-muted-foreground text-xs">
                    Current password
                  </Label>
                  <FormInput
                    form={form}
                    validation={{
                      required: "Password is required",
                      minLength: { value: 6, message: "Short password" },
                    }}
                    fieldName="currentPassword"
                    type={"currentPassword"}
                    className="!h-10"
                  />
                </div>

                <div className="flex flex-col space-y-1.5">
                  <Label className="text-muted-foreground text-xs">
                    New password
                  </Label>
                  <FormInput
                    form={form}
                    validation={{
                      pattern: {
                        message: "password validation",
                        value: PASSWORD_PATTERNS,
                      },
                      required: "Password is required",
                      minLength: { value: 6, message: "Short password" },
                      validate: (value) => {
                        if (value === form.watch("currentPassword"))
                          return "new and old passwords must not be the same";
                        return (
                          value === form.watch("newPasswordConfirmation") ||
                          "New passwords do not match"
                        );
                      },
                    }}
                    className="!h-10"
                    fieldName="newPassword"
                    type={"newPassword"}
                  />
                </div>
                <div className="flex flex-col space-y-1.5">
                  <Label className="text-muted-foreground text-xs">
                    Confirm new password
                  </Label>
                  <FormInput
                    form={form}
                    validation={{
                      required: "Password is required",
                      validate: (value) => {
                        return (
                          value === form.watch("newPassword") ||
                          "New passwords do not match"
                        );
                      },
                    }}
                    fieldName="newPasswordConfirmation"
                    type="newPasswordConfirmation"
                    className="!h-10"
                  />
                </div>
              </form>
            </DialogDescription>
            <DialogFooter>
              <Button
                onClick={onSubmit}
                className="w-fit self-start"
                variant="outline"
                size="sm"
              >
                Confirm change
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>

        <Dialog>
          <DialogTrigger asChild>
            <Button size={"sm"} className="w-52" variant={"outline"}>
              Change email
            </Button>
          </DialogTrigger>

          <DialogContent className="sm:max-w-[425px] rounded-lg-native">
            <DialogHeader className="font-medium text-xl mb-4">
              Change email
            </DialogHeader>
            <DialogTitle hidden />
            <DialogDescription>
              <form className="flex flex-col gap-4">
                <div className="flex flex-col space-y-1.5">
                  <Label className="text-muted-foreground text-xs">
                    New email
                  </Label>
                  <FormInput
                    form={emailForm}
                    validation={{
                      pattern: {
                        message: "Please, type valid email",
                        value: EMAIL_PATTERN,
                      },
                      required: "Email is required",
                    }}
                    fieldName="newEmail"
                    className="!h-10"
                  />
                </div>
                <div className="flex flex-col space-y-1.5">
                  <Label className="text-muted-foreground text-xs">
                    Email confirmation
                  </Label>
                  <FormInput
                    form={emailForm}
                    fieldName={"newEmailConfirmation"}
                    validation={{
                      required: "Emails do not match",
                      validate: (value) => {
                        return (
                          value === emailForm.watch("newEmail") ||
                          "Emails do not match"
                        );
                      },
                    }}
                  />
                </div>
                <div className="flex flex-col space-y-1.5">
                  <Label className="text-muted-foreground text-xs">
                    Current password
                  </Label>
                  <FormInput
                    form={emailForm}
                    fieldName={"currentPassword"}
                    validation={{
                      required: "Password is required",
                    }}
                  />
                </div>
              </form>
            </DialogDescription>
            <DialogFooter>
              <Button
                onClick={onSubmitEmail}
                className="w-fit h-8 self-start"
                variant="outline"
                size="sm"
              >
                Change email
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>

        <Dialog className="self-start">
          <DialogTrigger>
            <Button size="sm" className="max-w-52" variant="exit">
              Unsubscribe from newsletter
            </Button>
          </DialogTrigger>
          <DialogContent className="sm:max-w-[425px] rounded-lg-native">
            <DialogHeader className="font-medium text-xl mb-4">
              Are you sure you want to unsubscribe from newsletter?
            </DialogHeader>
            <DialogTitle hidden />
            <DialogDescription hidden />
            <DialogFooter>
              <DialogClose asChild>
                <Button
                  onClick={close}
                  className="w-fit h-8 self-start"
                  variant="outline"
                  size="sm"
                >
                  Cancel
                </Button>
              </DialogClose>
              <Button
                onClick={handleUnsubscribe}
                className="w-fit h-8 self-start"
                variant="exit"
                size="sm"
              >
                I'm sure
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
};
