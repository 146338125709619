import { useQueryClient } from "@tanstack/react-query";
import { FC, PropsWithChildren, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";

const Timer: FC<PropsWithChildren> = ({ children }) => {
  const [timeLeft, setTimeLeft] = useState(3);

  useEffect(() => {
    if (timeLeft <= 0) return;

    const timer = setInterval(() => {
      setTimeLeft((prev) => prev - 1);
    }, 1000);

    return () => clearInterval(timer); // Очистка таймера
  }, [timeLeft]);

  return (
    <div className={"flex"}>
      {children} {timeLeft}{" "}
    </div>
  );
};

export const SuccessPaymentPage = () => {
  const queryClient = useQueryClient();
  const navigation = useNavigate();

  useEffect(() => {
    ReactGA.send({
      hitType: "event",
      eventCategory: "Click",
      eventAction: "trial_launched",
      eventLabel: "method",
      name: "trial_launched",
      value: 1,
    });

    const refetch = async () => {
      await queryClient.invalidateQueries({ queryKey: ["me"] });
      navigation("/trends");
    };

    setTimeout(() => {
      void refetch();
      localStorage.removeItem("subscription-type");
    }, 3000);
  }, []);

  return (
    <div className={"w-full h-full flex justify-center items-center"}>
      <Timer> Successful payment, you will be redirected in</Timer>
    </div>
  );
};
