import { create } from "zustand";

interface SurveyState {
  formPage: number;
  setFormPage: (page: number) => void;
}

const useSurveyStore = create<SurveyState>()((set) => ({
  formPage: 0,
  setFormPage: (page: number) => set({ formPage: page }),
}));

export default useSurveyStore;
