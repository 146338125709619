import useUnsubscribeForm from "@/pages/CancelNewsletterPage/useUnsubscribeForm.tsx";
import { useNavigate } from "react-router-dom";
import { ChevronLeft } from "lucide-react";
import { Label } from "@/components/ui/label.tsx";
import { FormInput } from "@/components/form/FormInput.tsx";
import { Button } from "@/components/ui/button.tsx";

const CancelNewsletterPage = () => {
  const navigation = useNavigate();
  const { form, onSubmit } = useUnsubscribeForm();

  const handleBack = () => {
    navigation(-1);
  };

  return (
    <div className="w-full h-screen bg-white flex items-center justify-center">
      <div className="relative flex gap-20 flex-col items-center justify-center">
        <ChevronLeft
          width={28}
          height={28}
          className="absolute -top-32 -left-2 text-text-2 transition hover:text-gray-300 cursor-pointer"
          onClick={handleBack}
        />
        <p className="text-h3">Are you sure you want to unsubscribe?</p>
        <div className="flex flex-row items-center justify-center mt-2">
          <form onSubmit={onSubmit}>
            <div className="flex flex-col items-center gap-4">
              <div>
                <Label>Enter your email to confirm:</Label>
                <FormInput
                  className="w-80"
                  form={form}
                  fieldName={"email"}
                  validation={{
                    required: "Email is required",
                    pattern: {
                      value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                      message: "Invalid email format",
                    },
                  }}
                />
              </div>
              <Button type="submit" variant="exit" className="w-full sm:w-32">
                Unsubscribe
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CancelNewsletterPage;
