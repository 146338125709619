import { useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import AxiosInstance from "@/services/axios-instance.ts";

const useUnsubscribeForm = () => {
  const form = useForm<{ email: string }>({
    mode: "onSubmit",
    shouldFocusError: false,
    defaultValues: {
      email: "",
    },
  });

  const onUnsubscribe = useCallback(async () => {
    const { email } = form.getValues();
    const url = `/unsubscribe-from-newsletters`;

    try {
      await AxiosInstance.post(url, { email: email.trim() });
      toast.success("You have been unsubscribed from the newsletter.");
      form.reset();
    } catch (error) {
      console.error("Unsubscribe error", error.response?.data?.message);
      toast.error("Failed to unsubscribe. Please try again.");
    }
  }, [form]);

  return useMemo(
    () => ({
      form,
      onSubmit: form.handleSubmit(onUnsubscribe),
    }),
    [form, onUnsubscribe],
  );
};

export default useUnsubscribeForm;
