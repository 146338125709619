export const roles = [
  {
    role: "Amazon Seller",
    description: "I am currently selling or planning to sell on Amazon",
  },
  {
    role: "Employee",
    description: "I work as an employee or an assistant for Amazon business",
  },
  {
    role: "Agency",
    description:
      "I work with multiple sellers who sell on Amazon within an agency",
  },
  {
    role: "Freelancer",
    description:
      "I offer specialized services on a contract or per-project basis for Amazon business",
  },
  {
    role: "Other",
    description: "",
  },
];

export const business = [
  "Private Label",
  "Wholesale / Vendor",
  "Online or Retail Arbitrage",
  "Dropshipping",
  "Other",
];

export const experience = [
  "I'm just getting started",
  "Less than a year",
  "One to Three years",
  "Three years or more",
];

export const sources = [
  "Facebook",
  "Instagram",
  "Google",
  "Courses",
  "YouTube",
  "Friend's advice",
  "Other",
];
